
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    approve: Boolean,
    appropriate: Boolean,
    appropriate_check: Boolean,
    id: String,
    type: String
  },
  data(){
    return {
      loading: false
    }
  },
  methods: {
    async inappropriate(){
      this.loading = true
      this.axios.post(`/hotseat/${this.id}`).then(res=>{
        if(res.data.success){
          Swal.fire({
            text: res.data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: "btn fw-bold btn-light-success",
            },
          })
          
          setTimeout(()=>this.$router.go(0), 2000)
        }else{
          this.loading = false
          Swal.fire({
            text: res.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try Again",
            customClass: {
                confirmButton: "btn fw-bold btn-light-success",
            },
          })
        }
      }).catch(e=>{
        this.loading = false
        Swal.fire({
          text: e.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try Again",
          customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
          },
        })
      })
    },
    async approvefeedback(){
      this.loading = true
      this.axios.post(`/feedback/${this.id}`).then(res=>{
        if(res.data.success){
          Swal.fire({
            text: res.data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: "btn fw-bold btn-light-success",
            },
          })
          setTimeout(()=>this.$router.go(0), 2000)
        }else{
          this.loading = false
          Swal.fire({
            text: res.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try Again",
            customClass: {
                confirmButton: "btn fw-bold btn-light-success",
            },
          })
        }
      }).catch(e=>{
        this.loading = false
        Swal.fire({
          text: e.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try Again",
          customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
          },
        })
      })
    },
    async decline(){
      this.loading = true
      await this.axios.put(`/feedback/${this.id}`).then(res=>{
        if(res.data.success){
          Swal.fire({
            text: res.data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: "btn fw-bold btn-light-success",
            },
          })
          
          setTimeout(()=>this.$router.go(0), 2000)
        }else{
          this.loading = false
          Swal.fire({
            text: res.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try Again",
            customClass: {
                confirmButton: "btn fw-bold btn-light-success",
            },
          })
        }
      }).catch(e=>{
        this.loading = false
        Swal.fire({
          text: e.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try Again",
          customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
          },
        })
      })
    }
  }
});
