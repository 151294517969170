
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
    name: "push",
    components: {
        MessageIn,
        MessageOut
    },
    data(){
        return {
            feedback: [],
            title: '',
            body: ''
        }
    },
    methods: {
        async notifications(){
            try{
                let response = await this.axios.get('/notification')
                console.log(response.data)
                this.feedback = response.data.notifications
            }catch(e){
                console.log(e)
            }
        },
        async notify(){
          try{
              await this.axios.post('/notification', {
                title: this.title,
                body: this.body
              })

              this.title = ''
              this.body = ''
              this.notifications()

              Swal.fire({
                text: 'The notification has been sent to all InvestSika users.',
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Okay",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                },
              })
          }catch(e){
            Swal.fire({
              text: e.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Okay",
              customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
              },
            })
          }
        }
    },
    mounted(){
        setCurrentPageTitle('Push Notifications')
    },
    created(){
        this.notifications()
    }
});
