import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-lg-row" }
const _hoisted_2 = { class: "flex-lg-row-fluid ms-lg-7 ms-xl-10" }
const _hoisted_3 = {
  class: "card",
  id: "kt_chat_messenger"
}
const _hoisted_4 = {
  class: "card-header",
  id: "kt_chat_messenger_header"
}
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "d-flex justify-content-center flex-column me-3" }
const _hoisted_7 = { class: "mb-0 lh-1" }
const _hoisted_8 = { class: "fs-7 fw-bold text-gray-400" }
const _hoisted_9 = {
  class: "card-body",
  id: "kt_chat_messenger_body"
}
const _hoisted_10 = {
  key: 0,
  class: "scroll-y me-n5 pe-5 h-300px",
  ref: "messagesRef",
  "data-kt-element": "messages",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer",
  "data-kt-scroll-wrappers": "#kt_content, #kt_chat_messenger_body",
  "data-kt-scroll-offset": "-2px"
}
const _hoisted_11 = {
  class: "card-footer pt-4",
  id: "kt_chat_messenger_footer"
}
const _hoisted_12 = { class: "d-flex flex-stack" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageIn = _resolveComponent("MessageIn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createTextVNode(" Sent Push Notifications "),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", {
                  class: _normalizeClass(`badge badge-${_ctx.feedback.length > 0 ? 'success' : 'danger'} badge-circle w-10px h-10px me-1`)
                }, null, 2),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.feedback.length > 0 ? `(${_ctx.feedback.length} sent notificatinos)` : 'You have not sent any notifications yet'), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.feedback.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedback, (item, index) => {
                  return (_openBlock(), _createBlock(_component_MessageIn, {
                    key: index,
                    ref_for: true,
                    ref: "messagesInRef",
                    name: item.title,
                    id: item.id,
                    appropriate_check: false,
                    image: 'media/admin.png',
                    time: item.time,
                    text: item.body
                  }, null, 8, ["name", "id", "image", "time", "text"]))
                }), 128))
              ], 512))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
            class: "form-control form-control-flush mb-3",
            "data-kt-element": "input",
            placeholder: "Type in title"
          }, null, 512), [
            [_vModelText, _ctx.title]
          ]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.body) = $event)),
            class: "form-control form-control-flush mb-3",
            "data-kt-element": "input",
            placeholder: "Type in notification message"
          }, null, 512), [
            [_vModelText, _ctx.body]
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.notify && _ctx.notify(...args))),
              class: "btn btn-primary",
              type: "button",
              "data-kt-element": "send"
            }, " Send Push Notification ")
          ])
        ])
      ])
    ])
  ]))
}